import type { WritableComputedRef } from 'vue';

export enum QueryStringKey {
    AssetType = 'asset-type',
    AuctionHistoryLogType = 'auction-history-log-type',
    Brand = 'brand',
    BusFactoryAttributes = 'bfa',
    Model = 'model',
    Version = 'version',
    AdminUser = 'admin-user',
    AuctionState = 'auction-state[]',
    SaleState = 'sale-state[]',
    Company = 'company',
    CompanyState = 'company-state',
    User = 'user',
    EmailVerified = 'email-verified',
    Scrapyard = 'scrapyard',
    OnSiteDataCollected = 'on-site-data-collected',
    Search = 'q',
    Page = 'page',
    Sort = 'sort',
}

type QueryStringKeyArray = Extract<QueryStringKey, `${string}[]`>;
type QueryStringKeyScalar = Exclude<QueryStringKey, `${string}[]`>;

interface MakeQueryStringModel {
    <T>(key: QueryStringKeyArray): WritableComputedRef<T[]>;
    <T>(key: QueryStringKeyScalar): WritableComputedRef<T | undefined>;
}

type UseQueryStringKeysReturn = {
    makeQueryStringModel: MakeQueryStringModel;
};

if (Object.values(QueryStringKey).length !== new Set(Object.values(QueryStringKey)).size) {
    throw new Error('Duplicated values found in QueryStringKey!');
}

export const useQueryStringKeys = (): UseQueryStringKeysReturn => {
    const route = useRoute();

    const makeQueryStringModel: MakeQueryStringModel = <T extends string | number>(key: QueryStringKey): WritableComputedRef<T | T[] | undefined> =>
        computed({
            get: () => (key.endsWith('[]') ? (locationQueryAsStringArray(route.query[key]) as T[]) : (route.query[key] as T | undefined)),
            set: (value: T | T[] | undefined): void => {
                navigateTo({
                    query: { ...route.query, [key]: value !== '' ? value : undefined, [QueryStringKey.Page]: undefined },
                });
            },
        });

    return { makeQueryStringModel };
};
